@use "src/styles/fonts";

.bottom-navigation-bar-container {
  white-space: nowrap;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  bottom: 0;
  background-color: #000000;
  z-index: 1000;
  padding: 15px 24px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  .bottom-navigation-link {
    width: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 6px;
    background-color: inherit;
    color: white;
    text-decoration: none;
    column-gap: 3%;

    &.active-link {
      .active-icon {
        display: block;
      }

      .inactive-icon {
        display: none;
      }

      & > span {
        color: inherit;
        @include fonts.lato-font(0.625rem, 600);
      }
    }

    &.inactive-link {
      .active-icon {
        display: none;
      }

      .inactive-icon {
        display: block;
      }

      & > span {
        color: inherit;
        @include fonts.lato-font(0.563rem, 400);
      }
    }
  }
}
