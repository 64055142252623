.discovery-content-video-container {
  position: relative;
  width: 100%;
  height: 100%;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .controls {
    position: absolute;
    bottom: 135px;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    color: white;
    z-index: 10;
    margin: 5px 0 5px 0;

    button {
      background: none;
      border: none;
      color: white;
      font-size: 14px;
      cursor: pointer;
      z-index: 10;

      &:focus {
        outline: none;
      }

      .play-icon {
        height: 20px;
        width: 20px;
        margin-left: 5px;
      }

      .pause-icon {
        height: 20px;
        width: 20px;
        margin-right: 5px;
      }
    }

    .timeline {
      flex: 1;
      margin: 0 8px 0px 0px;
      height: 3px;
    }
  }

  @media (min-width: 461px) {
    .controls {
      margin: 5px 10px 5px 10px;
      padding: 10px 30px 10px 10px;
    }
  }
}
